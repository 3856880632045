import builder from '@builder.io/react';
import {
  Button as CButton,
  Center,
  Image,
  useMediaQuery
} from '@chakra-ui/react';
import Button from 'components/atoms/Button/Button';
import Icon from 'components/atoms/Icon/Icon';
import { Link, graphql } from 'gatsby';
import React, { useEffect, useMemo, useState } from 'react';
import Container from '../components/atoms/Container/Container';
import Layout from '../components/organisms/Layout/Layout';
builder.init(process.env.BUILDER_PUBLIC_API);
import * as pcStyles from './brands.module.scss';
import * as mpsStyles from './brands.mps.module.scss';
import { storeStyle, isMadPawsStore } from '../common/util';

const styles = storeStyle({mpsStyles, pcStyles}) ;
const BrandsPage = ({ data }) => {

  const [activeSection, setActiveSection] = useState('A');
  const [visibleItems, setVisibleItems] = useState(12);

  const [isPc] = useMediaQuery('(min-width: 800px)', {
    ssr: true,
    fallback: false
  });

  const content = data?.allBuilderModels?.oneImageGridBanner?.content;

  const getGroupingKey = name => {
    const firstChar = name.charAt(0);
    if (/[0-9]/.test(firstChar)) {
      return '123';
    }
    if (/[A-Za-z]/.test(firstChar)) {
      return firstChar.toUpperCase();
    }
    return '#';
  };
  const groupedData = useMemo(() => {
    // Group and sort the data
    const sortedEdges = [...data?.allBrands?.edges].sort((a, b) =>
      a.node.name.localeCompare(b.node.name)
    );
    const groups = sortedEdges.reduce((result, item) => {
      const key = getGroupingKey(item.node.name);
      if (!result[key]) {
        result[key] = [];
      }
      result[key].push(item.node);
      return result;
    }, {});

    // Sort the groups and map to the desired format
    return (
      Object.keys(groups)
        .sort((a, b) => {
          if (a === '#') return -1;
          if (b === '#') return 1;
          if (a === '123') return 1;
          if (b === '123') return -1;
          return a.localeCompare(b);
        })
        .map(key => ({
          brands: groups[key],
          ref: React.createRef(),
          grouping: key
        })) ?? []
    );
  }, [data]);


  const handleClick = clicked => {
    const findFromGroupedData = groupedData.find(
      group => group.grouping === clicked
    );
    if (findFromGroupedData && findFromGroupedData.ref.current) {
      const offsetTop =
        findFromGroupedData.ref.current.getBoundingClientRect().top +
        window.scrollY -
        (82 + 160); // Adjust the offset as needed
      window.scrollTo({ top: offsetTop, behavior: 'smooth' });
    }
    setActiveSection(clicked);
    return;
  };

  useEffect(() => {
    const handleScroll = () => {
      for (let group of groupedData) {
        if (
          group.ref.current &&
          group.ref.current.getBoundingClientRect().top >= 82
        ) {
          setActiveSection(group.grouping);
          break;
        }
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [groupedData]);

  useEffect(() => {
    if (isPc) {
      setVisibleItems(12);
    } else {
      setVisibleItems(6);
    }
  }, [isPc]);


  return (
    <Layout disablePaddingBottom className={styles.root}>
      <Container className={styles.root}>
        <h1 className="title">Popular brands</h1>
        <p className="subtitle">
          We have a wide selection of reputable brands to cater for all your
          pets needs
        </p>

        <section className="popular-brands">
          {content?.data?.gridBanners
            ?.slice(0, visibleItems)
            .map((banner, index) => {
              return (
                <Link
                  key={index}
                  className="brand shadow"
                  to={banner.redirectLink}
                >
                  <figure>
                    <img src={banner.image} alt="" />
                  </figure>
                  <p className="brand_name">{banner.text}</p>
                </Link>
              );
            })}
        </section>
        {visibleItems < content?.data?.gridBanners?.length && (
          <Center>
            <CButton
              rounded={'full'}
              my={8}
              color={'hsla(195, 72%, 28%, 1)'}
              fontSize={15}
              fontWeight={700}
              rightIcon={<Icon symbol={'chevDown'} />}
              variant={'ghost'}
              cursor={'pointer'}
              onClick={() => setVisibleItems(visibleItems + 6)}
            >
              More
            </CButton>
          </Center>
        )}
      </Container>
      <section className={`scroller `}>
        <Container>
          <div className="controls">
            {/* <button
              className={activeSection === '#' ? 'active' : ''}
              onClick={() => handleClick('#')}
            >
              #
            </button> */}
            {Array.from({ length: 26 }, (_, i) => (
              <button
                onClick={() =>
                  handleClick(String.fromCharCode(97 + i).toLocaleUpperCase())
                }
                className={
                  activeSection ===
                  String.fromCharCode(97 + i).toLocaleUpperCase()
                    ? 'active'
                    : ''
                }
                key={i}
              >
                {String.fromCharCode(97 + i).toLocaleUpperCase()}
              </button>
            ))}
            <button
              className={activeSection === '123' ? 'active' : ''}
              onClick={() => handleClick('123')}
            >
              123
            </button>
          </div>
        </Container>
      </section>
      <section className="scroller-brands">
        <Container>
          {groupedData?.map(group => (
            <div
              className="group"
              ref={group.ref}
              id={`brand_${group.grouping}`}
            >
              <h3 className="group-title">{group.grouping}</h3>
              <div className="group-brands">
                {group.brands.map(brand => (
                  <Link
                    className="brand underline"
                    to={brand.custom_url?.url}
                  >
                    {brand.name}
                  </Link>
                ))}
              </div>
            </div>
          ))}
        </Container>
      </section>

      <section className="contact-us">
        <div className="image">
          <Image m={0} src={isMadPawsStore() ? "/brand-footer-mps.png" : "/brand-footer.png"} alt="" />
        </div>
        <div className="content">
          <h1>Cannot find a product?</h1>
          <p>
            Our range of products and those available for supply by the
            pharmacist is constantly growing, but if there is a product you can
            obtain from your regular veterinarian that is not listed on our
            website, then let us know as it may be able to be sourced for you at
            a much lower cost{isMadPawsStore() ? '.' : ':'}
          </p>
          <Button
            className={'contact-us-button'}
            level={'tertiary'}
            type={'a'}
            target={'_blank'}
            href={'https://petchemist.brainfish.ai/'}
          >
            CONTACT US
          </Button>
        </div>
      </section>
    </Layout>
  );
};

export default BrandsPage;

export const query = graphql`
  query($path: String!) {
    allBuilderModels {
      oneImageGridBanner(
        target: { urlPath: $path }
        options: { cachebust: true }
      ) {
        content
      }
    }
    allBrands: allBigCommerceBrands {
      edges {
        node {
          bigcommerce_id
          name
          custom_url {
            url
          }
        }
      }
    }
  }
`;
